/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from 'react-pro-sidebar';

import { NavLink } from 'react-router-dom';
import {
  FaBalanceScale, FaList, FaDesktop, FaCalendarAlt,
} from 'react-icons/fa';

import { MdOutlineDocumentScanner } from 'react-icons/md';

import sidebarBg from '../../assets/bg-sidebar.jpg';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Aside = ({
  image, collapsed, toggled, handleToggleSidebar,
}) => {
  let loggedUserLevel = 0;

  const user = localStorage.getItem('@Jurisnet:user');
  const userData = JSON.parse(user);
  const userAccessInfo = JSON.parse(userData.access_info);

  // const accessData = sessionStorage.getItem('@Jurisnet:access');
  const accessData = localStorage.getItem('@Jurisnet:access');

  if (typeof accessData === 'string' && !userAccessInfo) {
    const access = JSON.parse(accessData);
    loggedUserLevel = Number(access.menu);
  }

  return (
    <>
      <ProSidebar
        image={image ? sidebarBg : false}
        collapsed={collapsed}
        toggled={toggled}
        breakPoint="md"
        onToggle={handleToggleSidebar}
      >
        <SidebarHeader icon={<FaBalanceScale />}>
          <div
            style={{
              padding: '24px',
              textTransform: 'uppercase',
              fontWeight: 'bold',
              fontSize: 14,
              letterSpacing: '1px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            JN 2.0
          </div>
        </SidebarHeader>

        <SidebarContent>
          <Menu iconShape="circle">
            {loggedUserLevel === 5 ? (
              <MenuItem icon={<FaDesktop />}>
                <NavLink to="/dashboard" exact activeClassName="active">
                  Dashboard
                </NavLink>
              </MenuItem>
            ) : null}

            <SubMenu
              title="Relatórios"
              icon={<FaList />}
              defaultOpen
            >
              <MenuItem icon={<MdOutlineDocumentScanner />}>
                <NavLink to="/report/financialinformationmodIII" exact activeClassName="active">
                  Listar todos processos
                </NavLink>
              </MenuItem>
              <MenuItem icon={<MdOutlineDocumentScanner />}>
                <NavLink to="/report/financialinformationmodIII/search" exact activeClassName="active">
                  Localizar processo
                  <br />
                  específico
                </NavLink>
              </MenuItem>
            </SubMenu>
          </Menu>
        </SidebarContent>

        <SidebarFooter style={{ textAlign: 'center' }} icon={<FaCalendarAlt />}>
          <div
            className="sidebar-btn-wrapper"
            style={{
              padding: '20px 24px',
            }}
          >
            <a
              href="/dashboard"
              target="_blank"
              className="sidebar-btn"
              rel="noopener noreferrer"
            >
              <span style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                Agenda
              </span>
            </a>
          </div>
        </SidebarFooter>
      </ProSidebar>
    </>
  );
};
export default Aside;
