import React, {
  createContext, useCallback, useState, useContext,
} from 'react';

import { useHistory } from 'react-router-dom';

import api from '../services/api';

interface User {
  id: string;
  name: string;
  email: string;
  avatar_url: string;
  active: boolean;
  user_level: number;
  login_name: string;
  legacy_id: string;
  access_info: string;
}

interface ManagementData {
  usuario_logado: string;
  categoria: string;
  setor: string;
  permissaoiv: string;
  permissaov: string;
  altSenha: string;
  menu: string;
  nivelusuario: string;
  clienteid: string;
  clientenome: string;
}

interface AuthState {
  token: string;
  user: User;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  user: User;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  updateUser(user: User): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const history = useHistory();

  const [data, setData] = useState <AuthState>(() => {
    const token = localStorage.getItem('@Jurisnet:token');
    const user = localStorage.getItem('@Jurisnet:user');

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;

      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ email, password }) => {
    const response = await api.post('sessions', {
      email,
      password,
    });

    const { token, user } = response.data;

    const access: ManagementData = {
      usuario_logado: '',
      categoria: '',
      setor: '',
      permissaoiv: '',
      permissaov: '',
      altSenha: '',
      menu: '',
      nivelusuario: '',
      clienteid: '',
      clientenome: '',
    };

    localStorage.setItem('@Jurisnet:token', token);
    localStorage.setItem('@Jurisnet:user', JSON.stringify(user));

    setData({ token, user });

    api.defaults.headers.authorization = `Bearer ${token}`;

    // TODO ADD Winston and loggin features
    // Inicio do sistema de log de acesso
    // $arquivolog = "log.txt";
    // $data = date( "d/m/Y H:i:s");
    // $ip = $REMOTE_ADDR;
    // //$host = getHostByAddr($REMOTE_ADDR);
    // $pagina = $PHP_SELF;
    // $browser = $HTTP_USER_AGENT;
    // TODO get administrator data and extra extraInformation

    (async () => {
      await api.get(`/management/administrators/findbyemail/${user.login_name}`).then((responseAdm) => {
        if (responseAdm.data) {
          access.usuario_logado = responseAdm.data.administrador_id;
          access.categoria = responseAdm.data.administrador_categoria;
          access.setor = responseAdm.data.administrador_setor;
          access.permissaoiv = responseAdm.data.permissao_relatorioiv;
          access.permissaov = responseAdm.data.permissao_relatoriov;
          access.altSenha = responseAdm.data.sta_new_senha;

          switch (responseAdm.data.administrador_categoria) {
            case 1:
              access.menu = '1';
              access.nivelusuario = 'Administrador';
              break;
            case 2:
              access.menu = '2';
              access.nivelusuario = 'Assistente';
              break;

            default:
              break;
          }

          // Grava nos logs
          // $fp = fopen("$arquivolog", "r");
          // $fcontent = fread($fp, filesize($arquivolog));
          // $towrite = "Login: $login | Nivel do usuario: $nivelusuario | Data: $data
          // | IP: $ip | Navegador: $browser\n $fcontent";
          // $fp2 = fopen("$arquivolog", "w+");
          // fwrite($fp2, $towrite);
          // fclose($fp);
          // fclose($fp2);

          // Redireciona para a página de consulta dos administradores, advogados e assistentes.
          // header("Location: /jurisnet/consulta/new-password.php");
        }
      });
      // }
      // });

      if (access.nivelusuario === '') {
        await api.get(`/management/attorneys/findbyemail/${user.login_name}`).then((responseAtt) => {
          if (responseAtt.data) {
            access.usuario_logado = responseAtt.data.advogado_id;
            access.categoria = responseAtt.data.advogado_categoria;
            access.setor = responseAtt.data.advogado_setor;
            access.permissaoiv = responseAtt.data.permissao_relatorioiv;
            access.permissaov = responseAtt.data.permissao_relatoriov;
            access.altSenha = responseAtt.data.sta_new_senha;
            access.menu = '2';
            access.nivelusuario = 'Advogado';

            // Grava nos logs
            // $fp = fopen("$arquivolog", "r");
            // $fcontent = fread($fp, filesize($arquivolog));
            // $towrite = "Login: $login | Nivel do usuario: $nivelusuario
            // | Data: $data | IP: $ip | Navegador: $browser\n $fcontent";
            // $fp2 = fopen("$arquivolog", "w+");
            // fwrite($fp2, $towrite);
            // fclose($fp);
            // fclose($fp2);
            // Termino da gravacao

          // Redireciona para a página de consulta dos administradores, advogados e assistentes.
          // header("Location: /jurisnet/consulta/new-password.php");
          }
        });
      }

      if (access.nivelusuario === '') {
        await api.get(`/customers/findbyemail/${user.login_name}`).then((responseCustomer) => {
          if (responseCustomer.data) {
            access.usuario_logado = responseCustomer.data.administrador_id;
            access.categoria = responseCustomer.data.administrador_categoria;
            access.altSenha = responseCustomer.data.sta_new_senha;
            access.menu = '3';
            access.nivelusuario = 'Cliente';
            access.clienteid = responseCustomer.data.cliente_id;
            access.clientenome = responseCustomer.data.cliente_nome;

            // Grava nos logs
            // $fp = fopen("$arquivolog", "r");
            // $fcontent = fread($fp, filesize($arquivolog));
            // $towrite = "Login: $login | Nivel do usuario: $nivelusuario | Data: $data
            // | IP: $ip | Navegador: $browser\n $fcontent";
            // $fp2 = fopen("$arquivolog", "w+");
            // fwrite($fp2, $towrite);
            // fclose($fp);
            // fclose($fp2);

            // Redireciona para a página de consulta dos administradores, advogados e assistentes.
            // header("Location: /jurisnet/consulta/new-password.php");
          }
        });
      }

      // sessionStorage.setItem('@Jurisnet:access', JSON.stringify(access));
      localStorage.setItem('@Jurisnet:access', JSON.stringify(access));
      const formData = {
        access_info: JSON.stringify(access),
        login_name: user.login_name,
      };
      await api.patch('/users/patch/access/logininfo', formData);
      // TODO: fix menu display based on session
      history.push('/');
    })();
    // , access
  }, [history]);

  const signOut = useCallback(() => {
    localStorage.removeItem('@Jurisnet:token');
    localStorage.removeItem('@Jurisnet:user');
    localStorage.removeItem('@Jurisnet:lawsuit');
    localStorage.removeItem('@Jurisnet:judgingbody');
    localStorage.removeItem('@Jurisnet:counter');
    sessionStorage.removeItem('@Jurisnet:access');
    localStorage.removeItem('@Jurisnet:access');

    setData({} as AuthState);
  }, []);

  const updateUser = useCallback((user:User) => {
    localStorage.setItem('@Jurisnet:user', JSON.stringify(user));

    setData({
      token: data.token,
      user,
    });
  }, [data.token]);

  return (
    <AuthContext.Provider value={{
      user: data.user, signIn, signOut, updateUser,
    }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  return context;
}

export { AuthProvider, useAuth };
