import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '../pages/SignIn';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';

import Profile from '../pages/Profile';
import Dashboard from '../pages/Dashboard';

import FinancialInformationModIIIReport from '../pages/Report/FinancialInformationModIII';
import FinancialInformationModIIIReportShow from '../pages/Report/FinancialInformationModIII/show';
import SearchFinancialModIIIInformation from '../pages/Report/FinancialInformationModIII/search';

const Routes: React.FC = () => {
  let loggedUserLevel = 0;

  // const accessData = sessionStorage.getItem('@Jurisnet:access');
  const accessData = localStorage.getItem('@Jurisnet:access');

  if (typeof accessData === 'string') {
    const access = JSON.parse(accessData);
    loggedUserLevel = Number(access.menu);
  }

  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/reset-password" component={ResetPassword} />
      <Route path="/profile" component={Profile} isPrivate />

      {loggedUserLevel === 5 ? (
        <Route path="/dashboard" component={Dashboard} isPrivate />
      ) : ('')}

      <Route path="/report/financialinformationmodIII" exact component={FinancialInformationModIIIReport} isPrivate />
      <Route path="/report/financialinformationmodIII/show" exact component={FinancialInformationModIIIReportShow} isPrivate />
      <Route path="/report/financialinformationmodIII/search" exact component={SearchFinancialModIIIInformation} isPrivate />

    </Switch>
  );
};

export default Routes;
