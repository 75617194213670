import React, { useEffect, useRef, useState } from 'react';

import PrimeReact from 'primereact/api';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Sidebar } from 'primereact/sidebar';
import { Toolbar } from 'primereact/toolbar';
import { Tooltip } from 'primereact/tooltip';

import { useHistory, useLocation } from 'react-router-dom';

import { nanoid } from 'nanoid';
import { useToast } from '../../../hooks/toast';
import { Container } from './styles';
import api from '../../../services/api';

 interface ILawsuitProperties {
  proc_id: number | null;
  proc_reg: string;
  proc_num_pasta : string;
  proc_cod_acao : number;
  proc_cod_orgao : number;
  proc_cod_procedimento : number;
  proc_data_ajuiz : Date;
  proc_data_ingresso : Date;
  proc_cod_cliente : number;
  proc_cod_adv_resp : number;
  proc_adv_adversa : string;
  proc_url : string;
  proc_terceiros : string;
  proc_cod_nat_interv : number;
  proc_reg_outros : string;
  proc_orgao_cidade : string;
  proc_orgao_uf : string;
  proc_autor : string;
  proc_reu : string;
  proc_assunto : string;
  proc_ult_atu : Date;
  proc_localizacao : string;
  proc_resumo : string;
  proc_cod_natureza : number;
  proc_situacao : string;
  proc_ult_mov_rel : Date;
  proc_em_acordo : number;
  proc_cod_ass_resp : number;
  proc_sta_gerencial : string;
  proc_observacao : string;
  status_lib_morto : string;
  obs_final_financeiro : string;
  status_liberacao : string;
  proc_tipo_processo : string;
  proc_pgto_ato : string;
  cliente_nome: string;
  advogado_nome: string;
}

interface ILawsuitProgressProperties {
  and_id: number;
  and_proc_id: number;
  and_data: Date;
  and_andamento:string;
  and_arquivo:string;
  and_obs :string;
  and_enviado:boolean;
  and_exibir:boolean;
  and_dat_alt: Date;
  id_adv_alt: number;
  nivelusuario:string;
}

const Lawsuit: React.FC = () => {
  PrimeReact.ripple = true;

  const user = localStorage.getItem('@Jurisnet:user');
  let customer_id = 0;
  if (user) {
    const userData = JSON.parse(user);
    customer_id = parseInt(userData.legacy_id, 10);
  }

  const emptyLawsuit = {
    proc_id: null,
    proc_reg: '',
    proc_num_pasta: '',
    proc_cod_acao: 0,
    proc_cod_orgao: 0,
    proc_cod_procedimento: 0,
    proc_data_ajuiz: null,
    proc_data_ingresso: null,
    proc_cod_cliente: customer_id || -999,
    proc_cod_adv_resp: 0,
    proc_adv_adversa: '',
    proc_url: '',
    proc_terceiros: '',
    proc_cod_nat_interv: 0,
    proc_reg_outros: '',
    proc_orgao_cidade: '',
    proc_orgao_uf: '',
    proc_autor: '',
    proc_reu: '',
    proc_assunto: '',
    proc_ult_atu: null,
    proc_localizacao: '',
    proc_resumo: '',
    proc_cod_natureza: 0,
    proc_situacao: '',
    proc_ult_mov_rel: null,
    proc_em_acordo: 0,
    proc_cod_ass_resp: 0,
    proc_sta_gerencial: '',
    proc_observacao: '',
    status_lib_morto: 'N',
    obs_final_financeiro: '',
    status_liberacao: 'N',
    proc_tipo_processo: '',
    proc_pgto_ato: 'N',
    cliente_nome: '',
    advogado_nome: '',
  };

  const [lawsuits, setLawsuits] = useState<ILawsuitProperties[]>([]);
  const [lawsuit, setLawsuit] = useState(emptyLawsuit);
  const [selectedLawsuits, setSelectedLawsuits] = useState<ILawsuitProperties[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);
  const [visibleRight, setVisibleRight] = useState(false);

  // para tabela
  const [globalFilter, setGlobalFilter] = useState('');

  // const [selectedRecord, setSelectedRecord] = useState<ILawsuitProperties[] | null>(null);

  const dt = useRef(null);
  const { addToast } = useToast();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    try {
      setLoading(true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let state = location.state as any;
      if (!state) {
        state = {
          proc_reg: '',
          proc_num_pasta: '',
          proc_reg_outros: '',
          proc_cod_acao: '',
          proc_cod_natureza: '',
          proc_orgao_cidade: '',
          proc_orgao_uf: '',
          proc_autor: '',
          proc_reu: '',
          proc_cod_cliente: customer_id,
          proc_cod_adv_resp: '',
          proc_cod_ass_resp: '',
          dateFilterType: 'data',
          registeredFilterType: 'data',
          latestUpdateFilterType: 'data',
          proc_data_ajuiz_inicio: null,
          proc_data_ajuiz_final: null,
          proc_data_ingresso_inicio: null,
          proc_data_ingresso_final: null,
          proc_ult_atu_inicio: null,
          proc_ult_atu_final: null,
          proc_em_acordo: '4',
          updatedin: 0,
          nochanges: 0,
          formValuesChanged: false,
          menu: '3',
        };
      }
      api.post('/lawsuits/main/search', state).then((response) => {
        setLawsuits(response.data);
      });
    } catch (err) {
      setLawsuits([]);
    } finally {
      setLoading(false);
    }
  }, [addToast, customer_id, location]);

  const columns = [
    { field: 'proc_reg', header: 'Processo nº', sortable: true },
    { field: 'proc_autor', header: 'Autor(es)', sortable: true },
    { field: 'proc_reu', header: 'Réu(s)', sortable: true },
    { field: 'acao_descricao', header: 'Tipo Ação', sortable: true },
    { field: 'proc_assunto', header: 'Assunto', sortable: true },
  ];

  const dynamicColumns = columns.map((col) => (
    <Column
      key={col.field}
      field={col.field}
      header={col.header}
      sortable
    />
  ));

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const showLawsuit = (rowData:any) => {
    const searchData = {
      proc_reg: rowData.proc_reg,
      proc_num_pasta: '',
      proc_reg_outros: '',
      proc_cod_acao: '',
      proc_cod_natureza: '',
      proc_orgao_cidade: '',
      proc_orgao_uf: '',
      proc_autor: '',
      proc_reu: '',
      proc_cod_cliente: customer_id,
      proc_cod_adv_resp: '',
      proc_cod_ass_resp: '',
      dateFilterType: 'data',
      registeredFilterType: 'data',
      latestUpdateFilterType: 'data',
      proc_data_ajuiz_inicio: null,
      proc_data_ajuiz_final: null,
      proc_data_ingresso_inicio: null,
      proc_data_ingresso_final: null,
      proc_ult_atu_inicio: null,
      proc_ult_atu_final: null,
      proc_em_acordo: '4',
      updatedin: 0,
      nochanges: 0,
      formValuesChanged: false,
      menu: '3',
    };

    history.push({
      pathname: '/report/financialinformationmodIII/show',
      state: searchData,
    });
  };

  const showAllLawsuits = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const state = location.state as any;
    history.push({
      pathname: '/report/financialinformationmodIII/show',
      state,
    });
  };

  const searchLawsuits = () => {
    history.push('/report/financialinformationmodIII/search');
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const actionBodyTemplate = (rowData: any) => (
    <>
      <Button
        type="button"
        icon="pi pi-print"
        className="p-button-rounded p-button-text p-button-plain p-mr-2"
        onClick={() => { showLawsuit(rowData); }}
        tooltip="Imprimir resultado"
        tooltipOptions={{ position: 'left' }}
      />
    </>
  );

  const header = (
    <div className="flex align-items-center export-buttons">
      <Button
        icon="pi pi-search"
        className="p-button-secondary p-mr-2"
        type="button"
        onClick={searchLawsuits}
        label="Nova consulta"
        tooltip="Nova consulta de processos"
        tooltipOptions={{ position: 'right' }}
      />
      <span className="p-input-icon-left ml-auto">
        <i className="pi pi-search" />
        <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Pesquisar na tabela" />
      </span>
    </div>
  );

  const paginatorLeft = <Button type="button" icon="pi pi-refresh" className="p-button-text" />;
  const paginatorRight = <Button type="button" icon="pi pi-cloud" className="p-button-text" />;

  const rightToolbarTemplate = () => (
    <Button
      type="button"
      icon="pi pi-file-pdf"
      onClick={() => showAllLawsuits()}
      className="p-button-info p-ml-auto"
      label="Imprimir todos"
      tooltip="Exportar todos para PDF / Imprimir"
      tooltipOptions={{ position: 'left' }}
    />
  );

  // const [lawsuitId, setLawsuitId] = useState(0);
  const [lawsuitProgresses, setLawsuitProgresses] = useState<ILawsuitProgressProperties[]>([]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const loadSelectedLawsuit = async (rowData: any) => {
    try {
      await api.get(`/lawsuits/main/show/${rowData.proc_id}`).then((response) => {
        setLawsuit(response.data);
      });

      await api.get(`/lawsuits/progresses/show/filtered/${rowData.proc_id}`).then((response) => {
        setLawsuitProgresses(response.data);
      });
    } catch (e) {
      addToast({
        type: 'error',
        title: 'Ocorreu um problema',
        description:
                  'Não foi possível carregar as informações do cadastro selecionado.',
      });
    }
  };

  const progressDateBodyTemplate = (rowData: { and_data: Date; }) => {
    if (lawsuitProgresses !== undefined) {
      const filteredDate = lawsuitProgresses.filter((item) => (
        item.and_data === rowData.and_data
      ), []);

      const dateInput = filteredDate[0].and_data?.toString();

      const dateBR = new Date(dateInput);
      const formattedDate = dateBR.toLocaleDateString('pt-BR', { timeZone: 'UTC' });

      return formattedDate;
    }
    return '';
  };

  let timerId = setTimeout(() => {
    setLoading(false);
  }, 200);

  return (
    <Container>
      <header>
        <div>
          <h1>Consulta processual</h1>
        </div>
      </header>

      <Sidebar
        className="p-sidebar-md"
        visible={visibleRight}
        position="right"
        onHide={() => setVisibleRight(false)}
      >
        <h4>
          <div className="leftAlign" style={{ float: 'left', width: '49%' }}>
            Resumo do processo
          </div>
        </h4>

        <div className="col-12 md:col-12">
          <table width="100%" id="lawsuit_summary" cellPadding={0} cellSpacing={0}>
            <tbody>
              <tr style={{ background: '#e2e2e2' }}>
                <td width="120" style={{ padding: '5px' }}><strong>Processo nº:</strong></td>
                <td style={{ padding: '5px' }}>{lawsuit.proc_reg}</td>
              </tr>
              <tr>
                <td width="120" style={{ padding: '5px' }}><strong>Autor(es):</strong></td>
                <td style={{ padding: '5px' }}>{lawsuit.proc_autor}</td>
              </tr>
              <tr style={{ background: '#e2e2e2' }}>
                <td width="120" style={{ padding: '5px' }}><strong>Réu(s):</strong></td>
                <td style={{ padding: '5px' }}>{lawsuit.proc_reu}</td>
              </tr>
              <tr>
                <td width="120" style={{ padding: '5px' }}><strong>Assunto:</strong></td>
                <td style={{ padding: '5px' }}>{lawsuit.proc_assunto}</td>
              </tr>
            </tbody>
          </table>

          <h4>Andamento do processo</h4>

          <DataTable
            tableClassName="table-lawsuitsprogresses table"
            id="table-lawsuitsprogresses"
            value={lawsuitProgresses}
            dataKey="and_id"
            stripedRows
            className="p-datatable-sm"
            responsiveLayout="scroll"
            paginator
            paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink"
            currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords}"
            rows={5}
            paginatorLeft={paginatorLeft}
            paginatorRight={paginatorRight}
            emptyMessage="Nenhum registro encontrado."
          >
            <Column key="and_data" field="and_data" header="Data" alignHeader="center" body={progressDateBodyTemplate} align="left" style={{ width: '100px' }} />
            <Column field="and_andamento" header="Andamento" alignHeader="center" align="left" />
          </DataTable>
        </div>

      </Sidebar>
      <div className="datatable-responsive">
        <div className="card">
          <Tooltip target=".export-buttons>button" position="bottom" />

          <Toolbar key={nanoid()} className="p-mb-4" right={rightToolbarTemplate} />
          <DataTable
            tableClassName="table-lawsuits table"
            id="table-lawsuits"
            ref={dt}
            value={lawsuits}
            loading={loading}
            editMode="row"
            dataKey="proc_id"
            header={header}
            stripedRows
            className="p-datatable-sm"
            paginator
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords}"
            rows={50}
            rowsPerPageOptions={[10, 20, 50]}
            paginatorLeft={paginatorLeft}
            paginatorRight={paginatorRight}
            globalFilter={globalFilter}
            emptyMessage="Nenhum registro encontrado."
            selectionMode="multiple"
            selection={selectedLawsuits}
            loadingIcon="pi pi-spin pi-spinner"
            onSelectionChange={(e) => {
              const selSelectedLawsuits = e.value as ILawsuitProperties[];
              setSelectedLawsuits(selSelectedLawsuits);
            }}
            onRowClick={(e) => {
              if (e.originalEvent.detail === 1) {
                timerId = setTimeout(() => {
                  loadSelectedLawsuit(e.data);
                  setVisibleRight(true);
                }, 200);
              }
            }}
            onRowDoubleClick={(e) => {
              clearTimeout(timerId);
              showLawsuit(e.data);
            }}
          >
            <Column field="row_number" header="Nº" sortable headerStyle={{ width: '3rem' }} />
            {dynamicColumns}
            <Column field="proc_id" style={{ display: 'none' }} />
            <Column body={actionBodyTemplate} style={{ width: '120px' }} />
          </DataTable>

        </div>
      </div>

    </Container>

  );
};

export default Lawsuit;
