/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
import React, { useCallback } from 'react';
import Switch from 'react-switch';
import { FaHeart, FaBars } from 'react-icons/fa';
import { FiLogOut } from 'react-icons/fi'; // FiClock
import { ScrollTop } from 'primereact/scrolltop';

import { Link, useHistory } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Main = ({
  collapsed,
  handleToggleSidebar,
  handleCollapsedChange,
  props,
}) => {
  const history = useHistory();

  const user = localStorage.getItem('@Jurisnet:user');
  const token = localStorage.getItem('@Jurisnet:token');

  const userData = JSON.parse(user);

  const signOut = useCallback(() => {
    localStorage.removeItem('@Jurisnet:token');
    localStorage.removeItem('@Jurisnet:user');
    localStorage.removeItem('@Jurisnet:lawsuit');
    localStorage.removeItem('@Jurisnet:judginbody');
    localStorage.removeItem('@Jurisnet:counter');
    sessionStorage.removeItem('@Jurisnet:access');
    localStorage.removeItem('@Jurisnet:access');

    history.push('/');
  }, [history]);

  if (token) {
    return (
      <main>
        <div
          className="btn-toggle"
          onClick={() => handleToggleSidebar(true)}
          role="none"
        >
          <FaBars />
        </div>
        <header>
          <section className="header-content">
            <div className="header-columns">
              <main className="header-main-left" />
              <main className="header-main-right">
                <span>
                  Olá,
                  {' '}
                  <Link to="/profile">
                    <strong>{userData.name.split(' ').slice(0, -1).join(' ')}</strong>
                    !
                  </Link>
                </span>
              </main>
              <aside className="header-sidebar-first">
                <div className="block top-toggle section-left">
                  <Switch
                    height={16}
                    width={30}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onChange={handleCollapsedChange}
                    checked={collapsed}
                    onColor="#219de9"
                    offColor="#bbbbbb"
                  />
                </div>
              </aside>
              <aside className="header-sidebar-second">
                <button
                  className="logout-button"
                  type="button"
                  onClick={signOut}
                >
                  <FiLogOut />
                  <p><small>Sair</small></p>
                </button>
              </aside>
            </div>
          </section>
        </header>

        <div style={{ maxWidth: '100%' }}>
          {props.children}
          <ScrollTop />
        </div>

        <footer>
          <small>
            ©
            {' '}
            {new Date().getFullYear()}
            {' '}
            made with
            {' '}
            <FaHeart style={{ color: 'red' }} />
            {' '}
            by
            {' '}
            <a target="_blank" rel="noopener noreferrer" href="https://pedn.com.br">
              Perspectiva Estratégias Digitais
            </a>
          </small>
        </footer>
      </main>
    );
  }
  return (
    <main>
      <div style={{ maxWidth: '100%' }}>
        {props.children}
      </div>
    </main>
  );
};

export default Main;
