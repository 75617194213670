/* eslint-disable react/require-default-props */
import React from 'react';
import {
  Route as ReactDomRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect,
  useHistory,
} from 'react-router-dom';

import { useAuth } from '../hooks/auth';
import { useToast } from '../hooks/toast';

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  component: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  ...rest
}) => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const history = useHistory();

  const parseJwt = (token: string) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };

  const authToken = localStorage.getItem('@Jurisnet:token');

  if (authToken) {
    const decodedJwt = parseJwt(authToken);
    if (decodedJwt.exp * 1000 < Date.now()) {
      localStorage.removeItem('@Jurisnet:token');
      localStorage.removeItem('@Jurisnet:user');
      sessionStorage.removeItem('@Jurisnet:access');
      localStorage.removeItem('@Jurisnet:access');

      addToast({
        type: 'error',
        title: 'Tempo de acesso expirado!',
        description:
              'O tempo de login ativo expirou. Por favor efetue novamente seu login.',
      });
      history.push('/');
    }
  }

  return (
    <ReactDomRoute
      {...rest}
      render={({ location }) => (isPrivate === !!user ? (
        <Component {...rest} />
      ) : (
        <Redirect to={{
          pathname: isPrivate ? '/' : '/report/financialinformationmodIII/search',
          state: { from: location },
        }}
        />
      ))}
    />
  );
};

export default Route;
