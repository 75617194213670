import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { locale, addLocale } from 'primereact/api';

import AppProvider from './hooks';
import Layout from './components/Layout/Layout.js';
import GlobalStyle from './styles/global';
import Routes from './routes';

// messages={messages[locale]}
const App: React.FC = () => {
  addLocale('pt_BR', {
    closeText: 'Fechar',
    prevText: 'Anterior',
    nextText: 'Próximo',
    currentText: 'Atual',
    monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    weekHeader: 'Semana',
    firstDay: 1,
    today: 'Hoje',
    clear: 'Limpar',
    isRTL: false,
    showMonthAfterYear: false,
    yearSuffix: '',
    timeOnlyTitle: 'Somente Horas',
    timeText: 'Tempo',
    hourText: 'Hora',
    minuteText: 'Minuto',
    secondText: 'Segundo',
    ampm: false,
    month: 'Mês',
    week: 'Semana',
    day: 'Dia',
    allDayText: 'O dia todo',
    passwordPrompt: 'Informe uma senha',
    weak: 'fraca',
    medium: 'razoável',
    strong: 'forte',
  });
  locale('pt_BR');

  return (
    <Router>
      <Route render={(props) => (
        <Layout {...props}>

          <AppProvider>
            <Routes />
          </AppProvider>

          <GlobalStyle />

        </Layout>
      )}
      />

    </Router>

  );
};

export default App;
