/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';

import { useHistory } from 'react-router-dom';

import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';

import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';

import {
  Container,
} from './styles';

interface SearchData {
  proc_reg: string;
  proc_num_pasta: string;
  proc_reg_outros : string;
  // proc_cod_acao: number;
  proc_cod_orgao: number;
  proc_cod_natureza: number;
  proc_orgao_cidade: string;
  proc_orgao_uf: string;
  proc_autor: string;
  proc_reu: string;
  proc_cod_cliente: number;
  proc_cod_adv_resp: number;
  proc_cod_ass_resp: number;
  proc_data_ajuiz_inicio: Date;
  proc_data_ajuiz_final: Date;
  proc_data_ingresso_inicio: Date;
  proc_data_ingresso_final: Date;
  proc_ult_atu_inicio: Date;
  proc_ult_atu_final: Date;
  proc_em_acordo: string;
  updatedin: number;
  nochanges: number;
  formValuesChanged: boolean;
}

const SearchFinancialInformationModIII:React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();

  const user = localStorage.getItem('@Jurisnet:user');
  let customer_id = '0';
  if (user) {
    const userData = JSON.parse(user);
    customer_id = userData.legacy_id;
  }

  const initialValues: SearchData = {
    proc_reg: '',
    proc_num_pasta: '',
    proc_reg_outros: '',
    // proc_cod_acao: 0,
    proc_cod_orgao: 0,
    proc_cod_natureza: 0,
    proc_orgao_cidade: '',
    proc_orgao_uf: '',
    proc_autor: '',
    proc_reu: '',
    proc_cod_cliente: 0,
    proc_cod_adv_resp: 0,
    proc_cod_ass_resp: 0,
    proc_data_ajuiz_inicio: new Date(),
    proc_data_ajuiz_final: new Date(),
    proc_data_ingresso_inicio: new Date(),
    proc_data_ingresso_final: new Date(),
    proc_ult_atu_inicio: new Date(),
    proc_ult_atu_final: new Date(),
    proc_em_acordo: '4',
    updatedin: 0,
    nochanges: 0,
    formValuesChanged: false,
  };

  const [lawsuitRegistrationNumber, setLawsuitRegistrationNumber] = useState('');
  const [natureSuitsOptions, setNatureSuitsOptions] = useState([{}]);
  const [author, setAuthor] = useState('');
  const [defendant, setDefendant] = useState('');
  const [natureId, setNatureId] = useState('');

  const validationSchema = null;

  useEffect(() => {
    (async () => {
      await api.get('lawsuits/naturesuits/show/all').then((response) => {
        response.data.map((natureSuit: { natureza_id: number, natureza_descricao:string}) => (
          natureSuitsOptions.push({
            name: natureSuit.natureza_descricao,
            value: natureSuit.natureza_id,
          })
        ));
      });
      natureSuitsOptions.splice(0, 1);
      setNatureSuitsOptions(natureSuitsOptions);
    })();
  }, [natureSuitsOptions]);

  const handleOnSubmit = async (
    values: SearchData,
    { setSubmitting }: FormikHelpers<SearchData>,
  ) => {
    try {
      // const access = JSON.parse(sessionStorage.getItem('@Jurisnet:access') || '{}');
      const access = JSON.parse(localStorage.getItem('@Jurisnet:access') || '{}');

      let menu = '';
      if (access) {
        menu = access.menu;
      }
      const searchFormData = {
        proc_reg: lawsuitRegistrationNumber,
        proc_num_pasta: '',
        proc_reg_outros: '',
        proc_cod_acao: '',
        // proc_cod_orgao: judgingBodyId,
        proc_cod_natureza: natureId,
        proc_orgao_cidade: '',
        proc_orgao_uf: '',
        proc_autor: author,
        proc_reu: defendant,
        proc_cod_cliente: customer_id,

        // {"id":"5f19603d-4c38-41be-8777-944c215448a6","name":"Cremer S/A","login_name":"cremer",
        // "email":"cremer@naoexiste.email",
        // "password":"$2a$08$Glar2xMktD8ktVTOEHWR3e8CI4HOgsQDnjCeYBIH34zDPYOvATKAe",
        // "avatar":null,"created_at":"2022-11-05T21:57:48.467Z",
        // "updated_at":"2022-11-05T21:57:48.467Z","active":true,"user_level":3,
        // "legacy_id":34,"access_info":null}

        proc_cod_adv_resp: '',
        proc_cod_ass_resp: '',
        dateFilterType: 'data',
        registeredFilterType: 'data',
        latestUpdateFilterType: 'data',
        proc_data_ajuiz_inicio: null,
        proc_data_ajuiz_final: null,
        proc_data_ingresso_inicio: null,
        proc_data_ingresso_final: null,
        proc_ult_atu_inicio: null,
        proc_ult_atu_final: null,
        proc_em_acordo: '4',
        updatedin: 0,
        nochanges: 0,
        formValuesChanged: false,
        menu,
      };

      history.push({
        pathname: '/report/financialinformationmodIII',
        state: searchFormData,
      });

      setSubmitting(false);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Ocorreu um problema',
        description:
              'Tente repetir a pesquisa ou contate o administrador.',
      });
    }
  };
  // TODO: define set form changed
  return (
    <Container>
      <div className="card">
        <header>
          <h3>
            Relatório de Informações Processuais
          </h3>
        </header>

        <Formik
          onSubmit={handleOnSubmit}
          validationSchema={validationSchema}
          enableReinitialize
          initialValues={initialValues}
        >
          {({
            isSubmitting,
            handleChange,
            handleSubmit,
            handleReset,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="fluid formgrid grid p-mt-10">
                <div className="col-12 md:col-6 colum-spacer-right">
                  <div className="field grid">
                    <div className="col-12 md:col-12">
                      <label htmlFor="proc_reg">
                        Nº do processo
                      </label>
                      <InputText
                        id="proc_reg"
                        name="proc_reg"
                        className="inputfield w-full"
                        onChange={(e) => {
                          setLawsuitRegistrationNumber(e.target.value);
                          handleChange(e);
                        }}
                        // onBlur={handleBlur}
                        value={lawsuitRegistrationNumber}
                      />
                    </div>
                    <div className="col-12 md:col-12">
                      <label htmlFor="proc_cod_natureza">
                        Natureza do processo
                      </label>
                      <Dropdown
                        id="proc_cod_natureza"
                        name="proc_cod_natureza"
                        options={natureSuitsOptions}
                        onChange={(e) => setNatureId(e.target.value)}
                        value={natureId}
                        className="inputfield w-full"
                        optionLabel="name"
                        optionValue="value"
                        filter
                        filterBy="name"
                        placeholder="Todas"
                        emptyFilterMessage="Nenhum registro encontrado"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12 md:col-6 column-divider">
                  <div className="field grid">
                    <div className="col-12 md:col-12">
                      <label htmlFor="proc_autor">
                        Autor
                      </label>
                      <InputText
                        id="proc_autor"
                        name="proc_autor"
                        className="inputfield w-full"
                        value={author}
                        onChange={(e) => setAuthor(e.target.value)}
                        autoFocus
                      />
                    </div>
                    <div className="col-12 md:col-12">
                      <label htmlFor="proc_reg_outros">
                        Réu
                      </label>
                      <InputText
                        id="proc_reu"
                        name="proc_reu"
                        className="inputfield w-full"
                        value={defendant}
                        onChange={(e) => setDefendant(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12 p-mt-5">
                  <div className="flex align-content-center justify-content-center field">
                    <Button
                      icon="pi pi-check"
                      iconPos="left"
                      label="Pesquisar processos"
                      className="p-mr-2"
                      type="submit"
                      disabled={isSubmitting}
                    />
                    <Button
                      icon="pi pi-undo"
                      iconPos="left"
                      label="Limpar campos"
                      className="p-button-secondary p-mr-2"
                      type="button"
                      onClick={handleReset}
                    />
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>

    </Container>

  );
};

export default SearchFinancialInformationModIII;
