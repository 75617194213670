import '../components/Layout/styles/App.scss';
import '../../node_modules/primereact/resources/themes/saga-blue/theme.css';
import '../../node_modules/primereact/resources/primereact.min.css';
import '../../node_modules/primeicons/primeicons.css';
import '../../node_modules/primeflex/primeflex.css';
import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  body {
    background: #EBEDF3;//#312e38;
    color: #676767;
    -webkit-font-smoothing: antialiased;
  }


  body, input, button {
    font-family: 'Roboto Slab', serif;
    font-size: 16px;
  }

  /* body {
    font-family: var(--font-family);
  } */

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }

  button {
    cursor: pointer;
  }

  .card {
    margin: 1rem;
    background: #ffffff;
    padding: 2rem;
    box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
    border-radius: 4px;
    margin-bottom: 2rem;
  }

  .column-divider {
    padding-left: 20px;
    padding-right: 20px;
    border-left: 1px solid #e2e2e2;
    border-right: 1px solid #e2e2e2;
  }
  .colum-spacer-left {
    padding-left: 20px;
  }

  .colum-spacer-right {
    padding-right: 20px;
  }

  .col-2 {
    width: 16.66%;
  }

  h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child {
    margin-top: 0;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 1.5rem 0 1rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: inherit;
}

hr {
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 30px;
  border: 1px solid #bdbdbd;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  width: 100%;
}

.input-error {
  border: 1px solid red;
}

  .p-d-flex {
    display: flex !important;
    margin-bottom: 10px;
  }
  .p-ml-2 {
    margin-left: 1rem !important;
  }
  .p-mr-2 {
    margin-right: 0.5rem !important;
  }
  .p-mt-5 {
    margin-top: 0.5rem !important;
  }
  .p-mt-10 {
    margin-top: 1.0rem !important;
  }

  .p-mt-20 {
    margin-top: 2.5rem !important;
  }

  .p-mb-10 {
    margin-bottom: 1rem !important;
  }

  .p-mb-20 {
    margin-bottom: 2.5rem !important;
  }
  /* .p-mb-4 {
    margin-bottom: 1.5rem !important;
  } */
  .p-ml-auto {
    margin-left: auto !important;
  }
  .p-pl-zero {
    padding-left: 0px !important;
  }

  .datatable-responsive .p-datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
    display: none;
  }

  .p-datatable-scrollable .p-datatable-thead {
    position: -webkit-sticky;
    position: sticky;
  }

  .datatable-responsive .p-datatable.p-datatable-responsive .table-header {
      display: flex;
      justify-content: space-between;
    }

    .datatable-responsive .p-paginator {
      padding: 1rem;
    }

    .datatable-responsive .table-header {
      display: flex;
      justify-content: space-between;
    }

    .p-datatable-thead .p-checkbox.p-component {
      display: none;
      visibility: hidden;
    }

    .red {
      color: red;
    }

  @media screen and (max-width: 40em) {
    .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-thead > tr > th,
    .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-tfoot > tr > td {
        display: none !important;
    }

    .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        width: 100%;
        float: left;
        clear: left;
        border: 0 none;
    }

    .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
        padding: .4rem;
        min-width: 30%;
        display: inline-block;
        margin: -.4em 1em -.4em -.4rem;
        font-weight: bold;
    }

    .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td:last-child {
        border-bottom: 1px solid var(--surface-d);
    }

    .datatable-responsive .p-paginator .p-paginator-current {
        margin-left: auto;
    }

/* width */
::-webkit-scrollbar {
    width: 3px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
    display: none;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #333;
    border-radius: 10px;
    display: none;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #333;
    display: block;
  }


.divider{
    width: 100%;
    height: 1px;
    border-radius: 1px;
    background: #333;
}

.container{
    margin-left: 300px;
    transition: margin-left .2s ease-in;
    padding: 50px;
    box-sizing: border-box;
}

.container.inactive{
    margin-left: 80px;
}

a.active {
    color: #ffffff !important;
    font-size: 120%;
}

.card {
    background-color: #ffffff !important;
    padding: 1.5rem;
    margin-bottom: 1rem;
    border-radius: 12px;
    box-shadow: 0px 3px 5px rgba(0,0,0,.02), 0px 0px 2px rgba(0,0,0,.05), 0px 1px 4px rgba(0,0,0,.08) !important;

    &.card-w-title {
        padding-bottom: 2rem;
    }
  }
}

.app main header {
  margin-bottom: 6px;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.display-linebreak {
  white-space: pre-wrap;
}

div#overlay-panel {
  z-index: 1009 !important;
}

.red-tooltip.p-tooltip .p-tooltip-arrow {
    border-top-color: var(--red-500);
}
.red-tooltip.p-tooltip .p-tooltip-text {
    background-color: var(--red-500);
}
.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 130px;
  height: 130px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0%  { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

`;
