/* eslint-disable react/jsx-filename-extension */
import React, { useState } from 'react';
import Aside from './Aside.js';
import Main from './Main.js';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function Layout(props) {
  const [collapsed, setCollapsed] = useState(false);
  const [image, setImage] = useState(true);
  const [toggled, setToggled] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [title, setTitle] = useState(false);

  const handleCollapsedChange = (checked) => {
    setCollapsed(checked);
  };

  const handleImageChange = (checked) => {
    setImage(checked);
  };

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  const handleTitle = (value) => {
    setTitle(value);
  };

  const token = localStorage.getItem('@Jurisnet:token');

  return (
    <>
      <div className={`app ${toggled ? 'toggled' : ''}`} style={{ display: 'flex' }}>
        {token ? (
          <Aside
            image={image}
            collapsed={collapsed}
            toggled={toggled}
            handleToggleSidebar={handleToggleSidebar}
          />
        ) : ('')}
        <Main
          image={image}
          toggled={toggled}
          collapsed={collapsed}
          handleToggleSidebar={handleToggleSidebar}
          handleCollapsedChange={handleCollapsedChange}
          handleImageChange={handleImageChange}
          props={props}
          title={handleTitle}
        />
      </div>
    </>
  );
}

export default Layout;
